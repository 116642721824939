<template>
    <main class="o-site-main">
        <Head>
            <Title>{{ pageData.seo.title }}</Title>
            <Meta name="description" :content="pageData.seo.metaDesc" />
            <Meta name="og:title" :content="pageData.seo.opengraphTitle" />
            <Meta name="og:description" :content="pageData.seo.opengraphDescription" />
            <Meta name="og:image" v-if="pageData.seo.opengraphImage" :content="pageData.seo.opengraphImage.sourceUrl" />
            <Meta name="twitter:title" :content="pageData.seo.twitterTitle" />
            <Meta name="twitter:description" :content="pageData.seo.twitterDescription" />
            <Meta name="twitter:image" v-if="pageData.seo.twitterImage" :content="pageData.seo.twitterImage.sourceUrl" />
        </Head>

        <component
            v-once
            v-for="(component, index) in pageData.components.components"
            :is="$formatComponentName(component.fieldGroupName, 'Page')"
            v-bind="component"
            :key="index" />
    </main>
</template>

<script setup lang="ts">
    import pageQuery from '~/queries/page';
    import { useMainStore } from '~/store';

    const route = useRoute();
    const headerTheme = ref(null)
    const query = gql(pageQuery);
    const pageData = useState('pageData', () => null);
    const variables = {
        slug: '/'
    };

    const getPagedata = async () => {
        const { data, error } = await useAsyncQuery(query, { ...variables });

        console.log(error)

        if(!(data && data.value && data.value.nodeByUri)){
            throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
        };

        pageData.value = data.value.nodeByUri;
        headerTheme.value = data.value.nodeByUri.pageOptions.headerStyle;
    }

    await getPagedata();

    onMounted(async () => {
        useMainStore().setHeaderTheme(headerTheme.value); // Pass the value of headerTheme
    });
</script>
